<template>
  <validation-observer
    ref="scoreLockPasswordSaveFormRef"
    #default="{invalid}"
  >
    <b-modal
      id="scoreLockPasswordSaveModal"
      centered
      title="Thêm mật khẩu khóa điểm"
      body-class="position-static"
      :no-close-on-backdrop="true"
      @show="onShow"
      @hide="onHide"
    >
      <b-form>
        <b-form-group label-for="accountIds">
          <template v-slot:label>
            Cán bộ <span class="text-danger">*</span>
          </template>
          <validation-provider
            #default="{ errors }"
            name="Cán bộ"
            rules="required"
          >
            <v-select
              v-model="accountIds"
              label="name"
              multiple
              :options="employees"
              :reduce="option => option.id"
            />
            <b-form-invalid-feedback :state="getElementState(errors)">
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </validation-provider>
        </b-form-group>
      </b-form>
      <template #modal-footer>
        <div class="w-100 d-flex justify-content-end">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
            :disabled="invalid"
            @click="onSave"
          >
            <span class="text-right">
              <feather-icon icon="CheckIcon" /> Lưu lại
            </span>
          </b-button>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-secondary"
            @click="$bvModal.hide('scoreLockPasswordSaveModal')"
          >
            <span class="text-right">
              <feather-icon icon="XIcon" /> Hủy
            </span>
          </b-button>
        </div>
      </template>

      <b-overlay
        no-wrap
        variant="white"
        spinner-variant="primary"
        blur="0"
        opacity=".75"
        rounded="sm"
        :show="isLoading"
      /></b-modal>
  </validation-observer>
</template>

<script>
import vSelect from 'vue-select'
import {
  BButton,
  BForm,
  BFormGroup,
  BFormInvalidFeedback,
  BModal,
  BOverlay,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import Ripple from 'vue-ripple-directive'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { getUser } from '@/auth/utils'

export default {
  name: 'ScoreLockPasswordSave',
  directives: {
    Ripple,
  },
  components: {
    BFormInvalidFeedback,
    BFormGroup,
    BForm,
    BModal,
    BButton,
    ValidationObserver,
    ValidationProvider,
    BOverlay,
    vSelect,
  },
  data() {
    return {
      user: getUser(),
      isLoading: false,
      accountIds: [],
    }
  },
  computed: {
    ...mapGetters({
      employees: 'scoreLockPassword/accountsNoPassword',
    }),
  },
  methods: {
    ...mapActions({
      getAccountsNoPassword: 'scoreLockPassword/getAccountsNoPassword',
      createScoreLockPassword: 'scoreLockPassword/createScoreLockPassword',
    }),
    async onShow() {
      this.isLoading = true
      try {
        await this.getAccountsNoPassword({ organizationId: this.user.orgId })
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    onHide() {
      this.$refs
        .scoreLockPasswordSaveFormRef
        .reset()
      this.accountIds = []
    },
    getElementState(errors) {
      return errors.length > 0 ? false : null
    },
    async onSave() {
      const valid = this.$refs
        .scoreLockPasswordSaveFormRef
        .validate()
      if (valid) {
        this.isLoading = true
        try {
          const response = await this.createScoreLockPassword({
            accountIds: this.accountIds,
          })
          if (response) {
            const { isSuccessful, message } = response
            if (isSuccessful) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'CheckIcon',
                  variant: 'success',
                },
              })
              this.$emit('succeed')
              this.$bvModal.hide('scoreLockPasswordSaveModal')
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'XCircleIcon',
                  variant: 'danger',
                },
              })
            }
          }
        } catch (e) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `[${e.code}] ${e.message}`,
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        } finally {
          this.isLoading = false
        }
      }
    },
  },
}
</script>
